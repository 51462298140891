/*global massTimesApp */
massTimesApp.service('parishService', ['$http', function ($http)
{
    //console.log("Parish Service reporting for duty.");

    this.latitude = 0;
    this.longitude = 0;
    this.detectLocation = true;
    this.locationName = null;

    this.getParishes = function (lat, longit, parishPage) {
        //console.log(lat);
        //console.log(long);
        return $http.get('https://apiv4.updateparishdata.org/Churchs/?lat=' + lat + '&long=' + longit + '&pg='+parishPage);
    };
}]);
